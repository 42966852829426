import { motion } from "framer-motion";
import PropTypes from "prop-types";

export default function TechSection() {
  const technologies = [
    {
      name: "React",
      icon: "⚛️",
      description: "Frontend Development",
      gradient: "from-blue-500 to-indigo-600",
    },
    {
      name: "Node.js",
      icon: "🟢",
      description: "Backend Development",
      gradient: "from-green-500 to-emerald-600",
    },
    {
      name: "Python",
      icon: "🐍",
      description: "AI & Automation",
      gradient: "from-yellow-500 to-amber-600",
    },
    {
      name: "AWS",
      icon: "☁️",
      description: "Cloud Infrastructure",
      gradient: "from-orange-500 to-red-600",
    },
    {
      name: "MongoDB",
      icon: "🍃",
      description: "Database",
      gradient: "from-green-500 to-teal-600",
    },
    {
      name: "Docker",
      icon: "🐳",
      description: "Containerization",
      gradient: "from-blue-500 to-cyan-600",
    },
    {
      name: "TensorFlow",
      icon: "🤖",
      description: "Machine Learning",
      gradient: "from-purple-500 to-fuchsia-600",
    },
    {
      name: "React Native",
      icon: "📱",
      description: "Mobile Development",
      gradient: "from-indigo-500 to-violet-600",
    },
  ];

  return (
    <section
      id="technologies"
      className="py-24 px-4 sm:px-6 lg:px-8 relative overflow-hidden bg-black"
    >
      {/* Subtle grid background */}
      <div className="absolute inset-0 bg-center opacity-5"></div>

      {/* Gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-black/95 to-black/90 z-0"></div>

      {/* Animated light beams */}
      <div className="absolute inset-0 opacity-10">
        {[...Array(5)].map((_, i) => (
          <motion.div
            key={`tech-beam-${i}`}
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: [0, 0.2, 0],
              scale: [0, 1.5, 0],
              rotate: [0, 45, 90],
            }}
            transition={{
              duration: 20,
              repeat: Infinity,
              delay: i * 4,
              ease: "easeInOut",
            }}
            className="absolute w-[40rem] h-[40rem] rounded-full bg-gradient-to-r from-white/5 to-transparent blur-3xl"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
            }}
          />
        ))}
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
        className="max-w-7xl mx-auto relative z-10"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8">
          {technologies.map((tech, index) => (
            <TechCard key={tech.name} tech={tech} index={index} />
          ))}
        </div>
      </motion.div>
    </section>
  );
}

const TechCard = ({ tech, index }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, margin: "-50px" }}
      transition={{ duration: 0.7, delay: index * 0.1 }}
      className="group relative"
    >
      {/* Hover glow effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-white/10 to-white/5 rounded-3xl blur-xl opacity-0 group-hover:opacity-100 transition-all duration-700 transform group-hover:scale-105"></div>

      <div className="relative backdrop-blur-sm rounded-3xl p-8 h-full border border-white/10 hover:border-white/20 transition-all duration-500 overflow-hidden bg-gradient-to-br from-white/[0.07] to-transparent">
        {/* Subtle gradient background on hover */}
        <div
          className={`absolute inset-0 bg-gradient-to-br ${tech.gradient} opacity-0 group-hover:opacity-5 transition-opacity duration-500`}
        />

        {/* Glass reflection effect */}
        <div className="absolute -top-[300%] -right-[300%] w-[600%] h-[600%] bg-gradient-to-br from-white/20 to-transparent opacity-0 group-hover:opacity-20 transition-opacity duration-700 transform rotate-45 group-hover:translate-x-[5%] group-hover:translate-y-[5%]"></div>

        <motion.div
          whileHover={{ scale: 1.05 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          className={`w-16 h-16 rounded-2xl bg-gradient-to-r ${tech.gradient} flex items-center justify-center text-4xl mb-6 shadow-lg`}
        >
          {tech.icon}
        </motion.div>

        <h3 className="text-2xl font-medium text-white mb-2 transition duration-300 group-hover:text-white/90">
          {tech.name}
        </h3>

        <p className="text-white/60 leading-relaxed group-hover:text-white/80 transition duration-300">
          {tech.description}
        </p>

        <motion.div
          initial={{ opacity: 0, y: 10 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 + index * 0.1, duration: 0.5 }}
          viewport={{ once: true }}
          className="mt-6 flex items-center text-white/70 group-hover:text-white font-medium"
        >
          <span className="text-sm">Learn more</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 ml-2 group-hover:translate-x-1 transition-transform"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </motion.div>
      </div>
    </motion.div>
  );
};

TechCard.propTypes = {
  tech: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    gradient: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
};
