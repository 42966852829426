import { motion } from "framer-motion";
import PropTypes from "prop-types";

export default function FollowSection() {
  const steps = [
    {
      title: "Discovery",
      description:
        "We begin with a deep dive into your vision, requirements, and business objectives through collaborative sessions.",
      icon: "🎯",
      gradient: "from-blue-600 to-indigo-800",
    },
    {
      title: "Planning & Strategy",
      description:
        "Our experts craft a detailed roadmap with precise technical specifications, timelines, and key milestones.",
      icon: "📋",
      gradient: "from-indigo-600 to-purple-700",
    },
    {
      title: "Design & Prototyping",
      description:
        "We create elegant, intuitive interfaces and interactive prototypes that prioritize user experience.",
      icon: "🎨",
      gradient: "from-purple-600 to-pink-700",
    },
    {
      title: "Development",
      description:
        "Our engineers transform designs into reality using cutting-edge technologies and industry best practices.",
      icon: "👨‍💻",
      gradient: "from-pink-600 to-red-700",
    },
    {
      title: "Testing & QA",
      description:
        "Comprehensive testing ensures flawless performance and reliability across all platforms and devices.",
      icon: "🔍",
      gradient: "from-red-600 to-orange-700",
    },
    {
      title: "Deployment",
      description:
        "We seamlessly launch your product with thorough documentation and personalized training.",
      icon: "🚀",
      gradient: "from-orange-600 to-yellow-700",
    },
    {
      title: "Maintenance & Support",
      description:
        "Continuous support, updates, and enhancements ensure your product evolves and performs optimally.",
      icon: "🛠️",
      gradient: "from-yellow-600 to-green-700",
    },
  ];

  return (
    <section
      className="py-24 px-4 sm:px-6 lg:px-8 relative overflow-hidden"
      id="process"
    >
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
        className="max-w-7xl mx-auto relative z-10"
      >
        <div className="grid grid-cols-1 gap-16">
          {steps.map((step, index) => (
            <ProcessStep
              key={step.title}
              step={step}
              index={index}
              isLast={index === steps.length - 1}
            />
          ))}
        </div>
      </motion.div>
    </section>
  );
}

const ProcessStep = ({ step, index, isLast }) => {
  const isEven = index % 2 === 0;

  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, delay: index * 0.1 }}
      viewport={{ once: true, margin: "-50px" }}
      className="group relative"
    >
      <div
        className={`flex flex-col ${
          isEven ? "md:flex-row" : "md:flex-row-reverse"
        } items-center gap-8`}
      >
        {!isLast && (
          <div className="absolute h-full w-[2px] bg-gradient-to-b from-blue-500/30 via-purple-500/30 to-pink-500/30 left-1/2 md:left-auto md:right-auto top-20 bottom-0 -z-10 md:mx-[3.75rem]" />
        )}

        <motion.div
          whileHover={{ scale: 1.1, rotate: [0, 5, -5, 0] }}
          transition={{ duration: 0.5 }}
          className="w-24 h-24 flex-shrink-0 relative group"
        >
          <div
            className={`absolute inset-0 bg-gradient-to-br ${step.gradient} rounded-2xl opacity-70 blur-sm group-hover:opacity-100 transition-opacity duration-500`}
          ></div>
          <div className="relative w-full h-full bg-gray-900/80 backdrop-blur-sm rounded-2xl border border-white/20 flex items-center justify-center text-4xl shadow-lg">
            <span className="group-hover:scale-125 transition-transform duration-500">
              {step.icon}
            </span>
          </div>
          <motion.div
            className="absolute -right-2 -top-2 w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-purple-600 flex items-center justify-center text-sm font-bold text-white shadow-lg"
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            transition={{ delay: 0.3, type: "spring", stiffness: 200 }}
            viewport={{ once: true }}
          >
            {index + 1}
          </motion.div>
        </motion.div>

        <motion.div
          whileHover={{ x: isEven ? 5 : -5 }}
          transition={{ duration: 0.3 }}
          className="flex-1 bg-gradient-to-br from-gray-800/80 to-gray-900/80 backdrop-blur-sm p-8 rounded-2xl border-l-4 border-t border-r border-b border-white/5 hover:border-white/10 transition-all duration-500 shadow-xl group-hover:shadow-2xl"
          style={{
            borderLeftColor: isEven ? "rgb(99, 102, 241)" : "rgb(168, 85, 247)",
            borderLeftWidth: "4px",
          }}
        >
          <motion.h3
            className={`text-2xl md:text-3xl font-bold mb-4 bg-gradient-to-r ${step.gradient} bg-clip-text text-transparent`}
          >
            {step.title}
          </motion.h3>
          <p className="text-gray-300 text-lg leading-relaxed">
            {step.description}
          </p>
          <motion.div
            initial={{ width: 0 }}
            whileInView={{ width: "100%" }}
            transition={{ duration: 1.2, delay: 0.5 }}
            viewport={{ once: true }}
            className={`h-[1px] bg-gradient-to-r ${step.gradient} opacity-30 mt-6`}
          />
        </motion.div>
      </div>
    </motion.div>
  );
};

ProcessStep.propTypes = {
  step: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    gradient: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  isLast: PropTypes.bool.isRequired,
};
