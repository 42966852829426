import { motion } from "framer-motion";

import NavBar from "./NavBar";
import HeroSection from "./HeroSection";
import ServicesSection from "./ServicesSection";
import TechSection from "./TechSection";
import FollowSection from "./FollowSection";
import FooterSection from "./FooterSection";

function App() {
  return (
    <div className="min-h-screen bg-black text-white">
      <NavBar />
      <HeroSection />
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
        viewport={{ once: true }}
        className="text-center py-20 px-6"
      >
        <h2 className="text-4xl md:text-5xl lg:text-6xl font-semibold tracking-tight">
          Our Expertise
        </h2>
        <p className="text-xl md:text-2xl text-gray-400 max-w-3xl mx-auto mt-6 leading-relaxed">
          Cutting-edge solutions powered by the latest technologies
        </p>
      </motion.div>
      <ServicesSection />
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
        viewport={{ once: true }}
        className="text-center py-20 px-6"
      >
        <h2 className="text-4xl md:text-5xl lg:text-6xl font-semibold tracking-tight">
          Our Process
        </h2>
        <p className="text-xl md:text-2xl text-gray-400 max-w-3xl mx-auto mt-6 leading-relaxed">
          We follow a proven methodology to transform your ideas into
          exceptional digital products
        </p>
      </motion.div>
      <FollowSection />
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
        viewport={{ once: true }}
        className="text-center py-20 px-6"
      >
        <h2 className="text-4xl md:text-5xl lg:text-6xl font-semibold tracking-tight">
          Our Tech Stack
        </h2>
        <p className="text-xl md:text-2xl text-gray-400 max-w-3xl mx-auto mt-6 leading-relaxed">
          We leverage cutting-edge technologies to build powerful, scalable
          solutions
        </p>
      </motion.div>
      <TechSection />
      <FooterSection />
    </div>
  );
}

export default App;
